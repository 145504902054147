import {
  blockProfile,
  fetchBlockedProfiles,
  unblockProfiles,
} from 'Webapp/shared/app/redux/actions/blocking-actions';

import { Connector } from 'Webapp/utils/connector';

const actions = {
  blockProfile,
  fetchBlockedProfiles,
  unblockProfiles,
};

export type ConnectBlockingProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;

const connectBlocking: Connector = {
  actions,
};

export default connectBlocking;
