import React from 'react';
import styled from '@emotion/styled';

import { LAYERS } from 'Style/layout.js';

// Components
import TooltipMenu from 'ComponentLibrary/menus/tooltip-menu.js';
import Button from 'Webapp/shared/app/components/button';
import MeatballsMenuIcon from 'ComponentLibrary/icons/meatballs-menu.js';
import ConfirmDialog from 'Webapp/shared/app/modals/confirm-dialog';

// Utils
import connector from 'Utils/connector';
import getWindow from 'Utils/get-window';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectBlocking, {
  ConnectBlockingProps,
} from 'Webapp/shared/app/connectors/connectBlocking';
import connectMute, {
  ConnectMuteProps,
} from 'Webapp/shared/app/connectors/connectMute';
import connectComments, {
  ConnectCommentsProps,
} from 'Webapp/shared/app/connectors/connectComments';

import withReport from 'Webapp/shared/app/hocs/withReport.js';
import withT from 'ComponentLibrary/hocs/withT.js';

const StyledMeatballsMenuIcon = styled(MeatballsMenuIcon)({
  height: '1.5em',
});

const StyledTooltipMenu = styled(TooltipMenu)({
  zIndex: LAYERS.MODAL_TOOLTIP,
});

interface CommentsContextMenuPassedProps {
  isSameUser: boolean;
  comment: Flipboard.Comment;
  section: Flipboard.Section;
  item: Flipboard.Item;
}

type CommentContextMenuProps = CommentsContextMenuPassedProps & {
  className: string;
  onReport: (arg: unknown) => void;
  t: Flipboard.TFunction;
} & ConnectBlockingProps &
  ConnectModalProps &
  ConnectMuteProps &
  ConnectCommentsProps;

const CommentContextMenu: React.FC<CommentContextMenuProps> = ({
  className,
  onReport,
  isSameUser,
  section,
  item,
  comment,
  blockProfile,
  muteAuthor,
  removeComment,
  showModal,
  t,
}) => {
  const { authorDisplayName, authorUsername, userid, service } = comment;

  const copyText = async () => {
    await getWindow().navigator.clipboard.writeText(comment.text);
  };

  const handleBlockingUser = () => blockProfile(comment);

  const handleFlaggingComment = async () => {
    onReport({
      section,
      item,
      comment,
      callbackAfterConfirm: async () => {
        const mutableAuthor: Flipboard.MutableAuthor = {
          username: authorUsername,
          authorDisplayName,
          userid,
          service,
        };
        await blockProfile(comment);
        await muteAuthor(mutableAuthor);
      },
    });
  };

  const handleRemoveComment = () => {
    const removeCommentProps = {
      name: 'remove-comment',
      title: t('are_you_sure'),
      message: t('confirm_remove_comment'),
      confirmLabel: t('remove'),
      onConfirm: () => removeComment(comment, item),
    };
    showModal(ConfirmDialog, removeCommentProps);
  };

  const contextMenuList = (
    <ul>
      {!isSameUser && (
        <>
          <li>
            <Button name="comment-block-button" onClick={handleBlockingUser}>
              <span>
                {t('block_user', {
                  username: comment.authorDisplayName,
                })}
              </span>
            </Button>
          </li>
          <li>
            <Button
              name="comment-report-button"
              onClick={handleFlaggingComment}
            >
              <span>{t('report')}</span>
            </Button>
          </li>
        </>
      )}
      <li>
        <Button name="comment-copy-button" onClick={copyText}>
          <span>{t('copy')}</span>
        </Button>
      </li>
      {comment.canDelete && (
        <li>
          <Button name="comment-delete" onClick={handleRemoveComment}>
            <span>{t('remove_comment')}</span>
          </Button>
        </li>
      )}
    </ul>
  );
  // }
  return (
    <StyledTooltipMenu
      className={className}
      hideAfterClicking
      toggleElement={<StyledMeatballsMenuIcon />}
    >
      {contextMenuList}
    </StyledTooltipMenu>
  );
};

export default connector(
  connectModal,
  connectBlocking,
  connectMute,
  connectComments,
)(withT(withReport(CommentContextMenu)));
