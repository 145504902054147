import isIntegerMatch from 'Utils/is-integer-match';

type Thing = Record<string, unknown>;
export const hasSameProperty = (
  key: string,
  a?: Thing,
  b?: Thing,
  options: { castToNumber: boolean } = { castToNumber: false },
) => {
  if (!a?.[key] || !b?.[key]) {
    return false;
  }
  if (options.castToNumber) {
    return isIntegerMatch(a[key] as number, b[key] as number);
  }
  return a[key] === b[key];
};

interface MightHaveUserId {
  userid?: Flipboard.UserId;
}
export const hasSameUserid = (a?: MightHaveUserId, b?: MightHaveUserId) =>
  hasSameProperty('userid', a as Thing, b as Thing, { castToNumber: true });
