import sentry from 'Webapp/utils/sentry';

import { BLOCKING_TYPES } from 'Webapp/action-types';
import {
  toastGenericError,
  toastShowInfoAction,
} from 'Webapp/shared/app/redux/actions/toast-actions.js';
import { set } from 'Webapp/utils/redux';
import { LoadState, Services } from 'Webapp/enums';
import { removeBlockedUserComments } from 'Webapp/shared/concepts/comments';

export const blockProfile =
  (author: Flipboard.BlockableProfile): Flipboard.Thunk =>
  async (dispatch, _, { flap, t }) => {
    try {
      const params = {
        serviceUserid: author.userid,
        service: Services.FLIPBOARD,
      };

      await flap.get('/social/block', {
        params,
      });

      dispatch(removeBlockedUserComments(author.userid));

      dispatch(
        toastShowInfoAction(
          t('you_have_blocked_username', {
            username: author.authorDisplayName,
          }),
        ),
      );
    } catch (e) {
      sentry.captureException(e as Error);
      toastGenericError();
    }
  };

export const unblockProfiles =
  (authors: Array<Flipboard.BlockableProfile>): Flipboard.Thunk =>
  async (_dispatch, _, { flap }) => {
    try {
      const params = {
        serviceUserid: authors.map(({ userid }) => userid).join(','),
        service: Services.FLIPBOARD,
      };

      await flap.get('/social/unblock', { params });
    } catch (e) {
      sentry.captureException(e as Error);
      toastGenericError();
    }
  };

const getBlockedProfilesPending = () =>
  set(BLOCKING_TYPES.GET_BLOCKED_PROFILES_PENDING, 'loaded', LoadState.PENDING);

const getBlockedProfilesSuccess = (
  blockedProfiles: Array<Flipboard.FlapAuthorProfile>,
) => ({
  type: BLOCKING_TYPES.GET_BLOCKED_PROFILES_SUCCESS,
  payload: {
    blockedProfiles,
    loaded: LoadState.SUCCESS,
  },
});

const getBlockedProfilesFailed = () =>
  set(BLOCKING_TYPES.GET_BLOCKED_PROFILES_FAILED, 'loaded', LoadState.FAILED);

export const fetchBlockedProfiles =
  (uid: Flipboard.UserId): Flipboard.Thunk =>
  async (dispatch, _, { flap }) => {
    try {
      dispatch(getBlockedProfilesPending());

      const { data } = await flap.get<Flipboard.FlapSocialBlocksResponse>(
        `/social/blocks/${uid}`,
        {
          params: { service: 'flipboard' },
        },
      );

      if (data.success) {
        const blockedProfiles = data.items.reduce((profiles, profile) => {
          const { userid, authorDisplayName, authorUsername } = profile;
          profiles.push({
            userid,
            authorDisplayName,
            authorUsername,
          });

          return profiles;
        }, [] as Array<Flipboard.FlapAuthorProfile>);

        dispatch(getBlockedProfilesSuccess(blockedProfiles));
      } else {
        dispatch(getBlockedProfilesFailed());
        toastGenericError();
      }
    } catch (e) {
      sentry.captureException(e as Error);
      dispatch(getBlockedProfilesFailed());
      toastGenericError();
    }
  };
